.md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.md\:grid-cols-\[200px_1fr\] {
    grid-template-columns: 200px 1fr;
}

.gap-8 {
    gap: 2rem;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.container1 {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    word-spacing: 3px;
    /* background: #e9e9e92b; */
    letter-spacing: 1px;
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.font-bold {
    font-weight: 700;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-left: 5px;
}

.my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mb-4 {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    margin-left: 30px;
    margin-right: 30px;
}

