.container {
  height: 100vh;
}

.mainDiv {
  height: 100%;
  display: flex;
  justify-content: right;
  background: url('/public/images/wavebackground.png');
  background-size: cover;
  /* padding-top: 3rem; */
}

.imageContainer {
  @media (max-width: 600px) {
    display: none; /* Hide the image on screens smaller than 600px (adjust as needed) */
  }
}

