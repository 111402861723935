.btn {
    border: none;
    width: 3.6em;
    height: 3.5em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    background: linear-gradient(0deg,#7cb9f3,#3f5eea);
    cursor: pointer;
    transition: all 450ms ease-in-out;
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
    inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 4px rgba(255, 255, 255, 0.2),
    0px 0px 180px 0px #9917FF;
  }
  

  
  .text {
    font-weight: 600;
    color: #AAAAAA;
    font-size: medium;
  }
  
  .btn:hover {
    background: linear-gradient(0deg,#7cb9f3,#9dabe9);
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
    inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 4px rgba(255, 255, 255, 0.2),
    0px 0px 180px 0px #9917FF;
    transform: translateY(-1px);
  }
  
  .btn:hover .text {
    color: white;
  }
  
  .btn:hover .sparkle {
    fill: white;
    transform: scale(1.2);
  } 

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-family: "Arial", sans-serif;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
  }
  
  .tooltip .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #4caf50;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
  }
  