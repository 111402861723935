.detailtextfield {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
}
.space {
  display: flex;
  justify-content: left;
  gap: 2rem;
}
.Cardstyle{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
