.formcard {
  width: 352px;
  height: 80%;
  margin-top: 15%;
  margin: auto;

}
.header {
  font-variant: normal;
  /* background: linear-gradient(
    0deg,
    hsl(0deg 0% 100%) 13%,
    hsl(52deg 100% 85%) 50%,
    hsl(55deg 100% 50%) 87%
  ); */
  text-align: center;
  padding: 1%;
  border-radius: 5px;
  color: #01357E;
}
.textfield {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 align-items: center;
  margin-top: 1rem;
  
}
.loginbutton {
  width: 276px;
  /* background-color: #f9da34 !important; */
  color: black;
  align-items: center;
  
}
.links {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.modal{
  background: url('/public/images/airplane.png');
 background-repeat: no-repeat;
 background-size: cover;
 height: 150px !important;
 background-position: 0px 40px;
}

.form-scroll-container {
  max-height: 780px; 
  overflow-y: auto; 
}