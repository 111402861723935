.wait , .iata_code {
  font-family: "Roboto", sans-serif !important;
}

.container{
  background-color: white !important;
  width: 100%;
  height: 100%;
}

.loader {
  /* position: absolute; */
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 40rem;
  height: 30rem;
  margin: 0 auto;
}

.wait {
  background-color:rgb(43, 43, 43);
  margin: 10% 0;
  padding: 1rem;
  border-radius: 2px;
}
.iata_code {
  font-size: 6rem;
  opacity: 0.3;
  top: 52%;
  position: absolute;
  color: #0099cc;
}
.departure_city {
  left: 0;
}

.arrival_city {
  right: 0rem;
}

.plane {
  position: absolute;
  margin: 0 auto;
  right: 40px;
  width: 100%;
}

.plane_img {
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.earth_wrapper {
  position: absolute;
  right: 40px;
  margin: 0 auto;
  width: 100%;
  top: 40%;
  padding-top: 2.7rem;
}

.earth {
  width: 140px;
  height: 140px;
  background: url("https://zupimages.net/up/19/34/6vlb.gif");
  border-radius: 100%;
  background-size: 300px;
  animation: earthAnim 12s infinite linear;
  margin: 0 auto;
  border: 1px solid #cdd1d3;
}

@keyframes earthAnim {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -340px;
  }
}

@media screen and (max-width : 720px) {
  .iata_code{
    font-size: 4rem;
  }
  .earth{
    width: 100px;
    height: 100px;
  }
  .plane{
    right: 40px;
    top: 44%;
    width: 100%;
  }
  .plane_img{
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 460px) {
  .departure_city {
    left: 0;
    right: 0;
    top: 30%;
    position: absolute;
    margin: 0 auto;
  }

  .arrival_city {
    left: 0;
    right: 0px;
    top: 70%;
    position: absolute;
    margin: 0 auto;
  }

  .earth{
    width: 80px;
    height: 80px;
  }
  .earth_wrapper{
    right: 10px;
  }
  .plane{
    right: 10px;
    top: 44%;
    width: 100%;
  }
  .plane_img{
    width: 130px;
    height: 130px;
  }
}
