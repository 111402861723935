@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
/* @import url("https://fonts.googleapis.com/css?family=Roboto:900&display=swap"); */
@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-calendars/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-lists/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-react-schedule/styles/material.css';

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif !important;
}

.menu-notification .css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;

}

::-ms-reveal {
  display: none;
}

.css-wgai2y-MuiFormLabel-asterisk{
  color : red;
}

.MuiFormLabel-asterisk{
  color: red;
}

.invoice_input_diabled {
  width: 100%;
  height: 2.2rem;
  font-size: 14px;
  cursor: not-allowed;
  padding-left: 12px;
  margin-top: 2px;
}

button:hover {
  background: 'none';
}

li {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.sidebar-nav a {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
}

.sidebar-nav .active {
  background-color: hsla(41, 100%, 67%, 0.911);
  padding-left: 20px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.884);
  box-shadow: 0px 0px 10px rgba(255, 91, 62, 0.4);
}

.header-nav .active {
  color: hsl(41, 100%, 67%);
  position: relative;
}

.header-nav .active::after {
  content: '';
  background-color: hsl(41, 100%, 67%);
  width: 120%;
  height: 2px;
  position: absolute;
  bottom: -10px;
  left: -10%;
}

.bgRemove {
  mix-blend-mode: multiply;
}

.form_style {
  outline: none;
  box-shadow: 2px 3px 0px 1px #b6b0aa;
  /* padding: 12px 10px; */
  border-radius: 2px !important;
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0.8);
}

.form_style:focus {
  transform: translateY(4px) !important;
  box-shadow: 0px 0px 0px 0px #e99f4c !important;
}


.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root,
.css-zrqpq1 {
  border: 2px solid rgba(0, 0, 0, 0.6) !important;
  padding: 0px 6px !important;
  border-radius: 4px !important;
  margin:2px 10px 0px 0px !important;
  /* position: absolute;
  top: -20px;
  right: 0px;
  z-index: 100; */
}

.css-dwjt6a-MuiButtonBase-root-MuiIconButton-root {
  color: white !important;
}

.sidebar-nav-new li {
  color: rgba(255, 255, 255, 0.145);
}

.header_glass_effect {
  background: rgba(114, 116, 119, 0.75) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  border: none !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row !important;
  width: 100%;
  padding-right: 2rem;
  height: 64px !important;
}

.active_nav {
  background-color: rgb(78, 100, 146) !important;
  border-right: 3px solid cyan !important;
}

.side_dash .css-10hburv-MuiTypography-root {
  font-size: 13px !important;
}

.radio_btn_pay .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 4px !important;
}

.side_dash .css-yb0lig {
  font-size: 13px !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Responsive styles for phone */
@media only screen and (max-width: 600px) {
  .numberstyle-qty input {
      width: 80px;
  }
  
  .numberstyle-qty .qty-btn {
      font-size: 14px;
  }

  /* Add more responsive styles as needed */
}

/* Responsive styles for tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .numberstyle-qty input {
      width: 100px;
  }

  .numberstyle-qty .qty-btn {
      font-size: 16px;
  }

  /* Add more responsive styles as needed */
}


.card-center-global{
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: 600;
  p: 1;
}


/* date picker */

.abc.Mui-disabled:not(.Mui-selected) {
  color: rgb(153, 153, 153) !important;
  background-color: white !important;
  border : 1px solid rgb(255, 255, 255) !important;
  
}


.abc {
  border : 1px solid rgba(110, 110, 110, 0.705) !important;
  color : rgb(19, 19, 19) !important;
  
}

.disabled {
  border : 1px solid #6e6e6e !important;
  color : #6e6e6e !important;
  /* background-color: rgba(226, 226, 226, 0.24) !important; */
  
}

/* .abc.Mui-selected {
  color: #fff !important;
  background-color: #7abdff !important;
  font-weight: 500;
} */
.customday .MuiDayCalendar-slideTransition{
min-height: 64vh !important;
}

.customdays .MuiDayCalendar-slideTransition{
  min-height: 50vh !important;
  }

  .custom-heading-text {
    color: #F9DA34;
    text-align: center;
    margin-bottom: 10px;
    font-family: 'Lucida Handwriting', cursive !important;
  }
  
  