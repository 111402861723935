.nav {
  background-color: #f28e44;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  padding: 6px 4px;
  color: white;
}
.nested-list-subheade {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-bottom: 1px solid var(#091e4224) !important;
  margin: 22px 10px 0 !important;
  /* padding-bottom: 20px !important; */
  font-size: 22 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
  padding : "0px"
}

.dpContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:330px;
    height: 250px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius : 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
}


.profilePic {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  vertical-align: middle;
  text-align: center;
  color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
}

.profilePic:hover {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  color: #fff;
  transition: all .5s ease-in-out;
  text-decoration: none;

}

.profilePic span {
  display: inline-block;
  padding-top: 4.5em;
  padding-bottom: 4.5em;
}

form input[type="file"] {
  display: none;
  cursor: pointer;
}

